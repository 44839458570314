import {
	AfterViewInit,
	Component,
	Input,
	Optional,
	Output,
	Self,
	ViewChild,
} from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { Address } from './address';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { Subject } from 'rxjs';
import { NgClass } from '@angular/common';

@Component({
	standalone: true,
	selector: 'br-address',
	templateUrl: 'address.component.html',
	styleUrls: ['address.component.scss'],
	imports: [
		NgClass,
		FormsModule,
		AutoCompleteModule,
		GoogleMapsModule,
		ButtonModule,
		TooltipModule,
	],
})
export class AddressComponent implements ControlValueAccessor, AfterViewInit {
	@Input()
	required: boolean = true;

	@Input()
	clear: boolean = false;

	@Output()
	changed: Subject<Address> = new Subject<Address>();

	private touched: boolean = false;
	protected value: Address = {} as Address;
	protected disabled: boolean = false;

	@ViewChild(GoogleMap)
	private mapWrapper: GoogleMap;

	/* main clinic address */
	public center: google.maps.LatLngLiteral = {
		lat: 37.97519174380103,
		lng: 23.752035038029767,
	};

	protected mapOptions: google.maps.MapOptions = {
		center: { ...this.center },
		zoom: 17,
	};

	protected marker: google.maps.Marker = new google.maps.Marker({
		position: this.mapOptions.center,
		title: $localize`You are here`,
	});

	protected mapSuggestions: google.maps.GeocoderResult[];
	protected overlays: any = [];

	private onTouched: Function = () => { };

	private onChange: Function = () => { };

	constructor(@Optional() @Self() protected ngControl: NgControl) {
		if (ngControl != null) {
			/** Setting the value accessor directly (instead of using
			the providers) to avoid running into a circular import. */
			ngControl.valueAccessor = this;
		}
	}

	ngAfterViewInit(): void {
		this.recenter();
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
	registerOnTouched(fn: Function): void {
		this.onTouched = fn;
	}
	registerOnChange(fn: Function): void {
		this.onChange = fn;
	}

	writeValue(value: Address): void {
		this.value = value;
		if (value?.latitude && value?.longitude) {
			this.mapOptions.center = {
				lat: this.value.latitude,
				lng: this.value.longitude,
			};
		} else {
			this.mapOptions.center = { ...this.center };
		}
		this.recenter();
	}

	protected markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	protected mapSearch(event: any) {
		let geocoder = new google.maps.Geocoder();
		geocoder.geocode(
			{ address: event.query },
			(results: any, status: any) => {
				if (status.toString() == 'OK') {
					this.mapSuggestions = results;
				} else {
					this.mapSuggestions = new Array();
				}
			}
		);
	}

	protected addressSelected(value: google.maps.GeocoderResult) {
		this.value = {
			fullAddress: value.formatted_address,
			latitude: value.geometry.location.lat(),
			longitude: value.geometry.location.lng(),
		};

		this.mapOptions.center = {
			lat: this.value.latitude,
			lng: this.value.longitude,
		};
		this.recenter();
		this.onChange(this.value);
	}

	private recenter() {
		this.mapWrapper?.googleMap?.setCenter(this.mapOptions.center);
		this.overlays = [
			this.marker.setPosition(this.mapOptions.center),
			this.marker.setMap(this.mapWrapper?.googleMap),
		];
	}

	protected addressCleared(event: Event) {
		this.value = this.required
			? null
			: {
				fullAddress: '',
				latitude: null,
				longitude: null,
			};
		this.onChange(this.value);
	}

	public setAddressAndSelect(address: Address): void {
		const geocoder = new google.maps.Geocoder();
		geocoder.geocode({ address: address.fullAddress }, (results, status) => {
			if (status === 'OK' && results.length > 0) {
				this.addressSelected(results[0]);
			}
		});
	}
}
