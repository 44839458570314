<p-button
	[disabled]="disabled"
	(onClick)="onChangeHandler(!model.value)"
	(onFocus)="onInputFocus($event)"
	(onBlur)="onInputBlur($event)"
	icon="mdi mdi-{{ model.value ? (onIcon || 'light-switch') : (offIcon || 'light-switch-off') }}"
	[pTooltip]="model.value ? onLabel : offLabel"
	tooltipPosition="left"
	[styleClass]="model.value ? (onClass || 'p-button-warning') : offClass"
	>
</p-button>
