import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';

import { MatchMode } from '../../../domain';
import { AbstractFilterComponent } from '../abstract';
import { TooltipModule } from 'primeng/tooltip';

@Component({
	standalone: true,
	selector: 'br-toggle-filter',
	templateUrl: './toggle-filter.component.html',
	imports: [FormsModule, ButtonModule, TooltipModule],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ToggleFilterComponent),
			multi: true,
		},
	],
})
export class ToggleFilterComponent extends AbstractFilterComponent {
	@Input()
	onLabel: string = '';

	@Input()
	offLabel: string = '';

	@Input()
	onIcon: string;

	@Input()
	offIcon: string;

	@Input()
	onClass: string;

	@Input()
	offClass: string;

	@Input()
	name: string;

	@Input()
	override matchMode: MatchMode | string = MatchMode.Custom;
}
