export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Scalar for BigDecimal */
  BigDecimal: { input: any; output: any; }
  /** Scalar for BigInteger */
  BigInteger: { input: any; output: any; }
  /** Scalar for Date */
  Date: { input: any; output: any; }
  /** Scalar for DateTime */
  DateTime: { input: any; output: any; }
  /** An object scalar */
  Object: { input: any; output: any; }
  /** Scalar for Time */
  Time: { input: any; output: any; }
};

export type AadeSource = {
  aadeResponse?: Maybe<Scalars['Object']['output']>;
  aadeStatus?: Maybe<AadeSyncStatus>;
  id?: Maybe<Scalars['Object']['output']>;
};

export enum AadeSyncStatus {
  Error = 'ERROR',
  Skipped = 'SKIPPED',
  Success = 'SUCCESS',
  Sync = 'SYNC'
}

export type AbstractAuditableEntity = EntityInterface & {
  __typename?: 'AbstractAuditableEntity';
  deleted: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Object']['output']>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type AbstractPrivateEntity = {
  __typename?: 'AbstractPrivateEntity';
  deleted: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Object']['output']>;
  pFlag?: Maybe<PFlagType>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type AbstractTreeEntity = {
  __typename?: 'AbstractTreeEntity';
  deleted: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Object']['output']>;
  parent?: Maybe<AbstractTreeEntity>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type BaseParamsInput = {
  filters?: InputMaybe<Array<InputMaybe<FilterMetadataInput>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  globalFilter?: InputMaybe<Scalars['String']['input']>;
  rows?: InputMaybe<Scalars['Int']['input']>;
  sorting?: InputMaybe<Array<InputMaybe<SortMetadataInput>>>;
};

export type Clinic = {
  __typename?: 'Clinic';
  businessAddress: Scalars['String']['output'];
  businessName: Scalars['String']['output'];
  code?: Maybe<Scalars['String']['output']>;
  defaultClinic?: Maybe<Clinic>;
  defaultExamLogo?: Maybe<Image>;
  defaultExaminer?: Maybe<Examiner>;
  defaultInvoiceLogo?: Maybe<Image>;
  defaultReceiptLogo?: Maybe<Image>;
  defaultReferralVisibility?: Maybe<Scalars['Boolean']['output']>;
  deleted: Scalars['Boolean']['output'];
  examinerSettings?: Maybe<Array<Maybe<ExaminerSettings>>>;
  id?: Maybe<Scalars['Object']['output']>;
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  representative: Scalars['String']['output'];
  signature: Image;
  taxOffice: Scalars['String']['output'];
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vatNumber: Scalars['String']['output'];
};

export type ClinicDtoInput = {
  businessAddress?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  defaultExamLogo?: InputMaybe<ImageDtoInput>;
  defaultExaminerId?: InputMaybe<Scalars['Int']['input']>;
  defaultInvoiceLogo?: InputMaybe<ImageDtoInput>;
  defaultReceiptLogo?: InputMaybe<ImageDtoInput>;
  defaultReferralVisibility: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  representative?: InputMaybe<Scalars['String']['input']>;
  signature?: InputMaybe<ImageDtoInput>;
  taxOffice?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CountPk = {
  __typename?: 'CountPK';
  doctorId: Scalars['Int']['output'];
  imageId: Scalars['Int']['output'];
};

export type Counter = EntityInterface & {
  __typename?: 'Counter';
  id?: Maybe<Scalars['Object']['output']>;
};

export enum DataOrigin {
  Parent = 'PARENT',
  Self = 'SELF'
}

export type Doctor = {
  __typename?: 'Doctor';
  avatar?: Maybe<Image>;
  clinic: Clinic;
  company: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  end1?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  end2?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  end3?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  end4?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  end5?: Maybe<Scalars['Time']['output']>;
  examsDeliveryMethod?: Maybe<ExamDeliveryMethod>;
  examsDeliveryMethodComment?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullAddress: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  invoiceAddress?: Maybe<Scalars['String']['output']>;
  invoiceName?: Maybe<Scalars['String']['output']>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  /** ISO-8601 */
  lastActivityDate?: Maybe<Scalars['Date']['output']>;
  /** ISO-8601 */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  lastNotifiedAt?: Maybe<Scalars['DateTime']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notificationsEnabled: Scalars['Boolean']['output'];
  orders?: Maybe<Array<Maybe<Order>>>;
  otherDebts?: Maybe<Scalars['Float']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodComment?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  recommendationsEnabled: Scalars['Boolean']['output'];
  referrals?: Maybe<Array<Maybe<Referral>>>;
  representative?: Maybe<Scalars['String']['output']>;
  resultsDeliveryMethod?: Maybe<ResultDeliveryMethod>;
  resultsDeliveryMethodComment?: Maybe<Scalars['String']['output']>;
  secretaryName?: Maybe<Scalars['String']['output']>;
  secretaryPhone?: Maybe<Scalars['String']['output']>;
  specialty: Scalars['String']['output'];
  /** ISO-8601 */
  start1?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  start2?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  start3?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  start4?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  start5?: Maybe<Scalars['Time']['output']>;
  statisticsEnabled: Scalars['Boolean']['output'];
  tags?: Maybe<Array<Maybe<Tag>>>;
  taxOffice?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  vatNumber?: Maybe<Scalars['String']['output']>;
  vip: Scalars['Boolean']['output'];
};

export type DoctorDashboard = {
  __typename?: 'DoctorDashboard';
  doctor?: Maybe<Doctor>;
  examsPerMonth?: Maybe<Scalars['String']['output']>;
  monthlyExams?: Maybe<Scalars['Int']['output']>;
  monthlyPatients?: Maybe<Scalars['Int']['output']>;
  threeMonthsExams?: Maybe<Scalars['Int']['output']>;
  threeMonthsPatients?: Maybe<Scalars['Int']['output']>;
  totalExams?: Maybe<Scalars['Int']['output']>;
  totalInvoices?: Maybe<Scalars['Int']['output']>;
  totalInvoicesAmount?: Maybe<Scalars['Float']['output']>;
  totalPatients?: Maybe<Scalars['Int']['output']>;
};

export type DoctorDto = {
  __typename?: 'DoctorDto';
  avatar?: Maybe<ImageDto>;
  clinicId?: Maybe<Scalars['Int']['output']>;
  company: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  end1?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  end2?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  end3?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  end4?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  end5?: Maybe<Scalars['Time']['output']>;
  examsDeliveryMethod?: Maybe<ExamDeliveryMethod>;
  examsDeliveryMethodComment?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullAddress?: Maybe<Scalars['String']['output']>;
  invoiceAddress?: Maybe<Scalars['String']['output']>;
  invoiceName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notificationsEnabled: Scalars['Boolean']['output'];
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodComment?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  recommendationsEnabled: Scalars['Boolean']['output'];
  representative?: Maybe<Scalars['String']['output']>;
  resultsDeliveryMethod?: Maybe<ResultDeliveryMethod>;
  resultsDeliveryMethodComment?: Maybe<Scalars['String']['output']>;
  secretaryName?: Maybe<Scalars['String']['output']>;
  secretaryPhone?: Maybe<Scalars['String']['output']>;
  specialty?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  start1?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  start2?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  start3?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  start4?: Maybe<Scalars['Time']['output']>;
  /** ISO-8601 */
  start5?: Maybe<Scalars['Time']['output']>;
  statisticsEnabled: Scalars['Boolean']['output'];
  taxOffice?: Maybe<Scalars['String']['output']>;
  vatNumber?: Maybe<Scalars['String']['output']>;
  vip: Scalars['Boolean']['output'];
};

export type DoctorDtoInput = {
  avatar?: InputMaybe<ImageDtoInput>;
  clinicId?: InputMaybe<Scalars['Int']['input']>;
  company: Scalars['Boolean']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  end1?: InputMaybe<Scalars['Time']['input']>;
  /** ISO-8601 */
  end2?: InputMaybe<Scalars['Time']['input']>;
  /** ISO-8601 */
  end3?: InputMaybe<Scalars['Time']['input']>;
  /** ISO-8601 */
  end4?: InputMaybe<Scalars['Time']['input']>;
  /** ISO-8601 */
  end5?: InputMaybe<Scalars['Time']['input']>;
  examsDeliveryMethod?: InputMaybe<ExamDeliveryMethod>;
  examsDeliveryMethodComment?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  invoiceAddress?: InputMaybe<Scalars['String']['input']>;
  invoiceName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  notificationsEnabled: Scalars['Boolean']['input'];
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentMethodComment?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  recommendationsEnabled: Scalars['Boolean']['input'];
  representative?: InputMaybe<Scalars['String']['input']>;
  resultsDeliveryMethod?: InputMaybe<ResultDeliveryMethod>;
  resultsDeliveryMethodComment?: InputMaybe<Scalars['String']['input']>;
  secretaryName?: InputMaybe<Scalars['String']['input']>;
  secretaryPhone?: InputMaybe<Scalars['String']['input']>;
  specialty?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  start1?: InputMaybe<Scalars['Time']['input']>;
  /** ISO-8601 */
  start2?: InputMaybe<Scalars['Time']['input']>;
  /** ISO-8601 */
  start3?: InputMaybe<Scalars['Time']['input']>;
  /** ISO-8601 */
  start4?: InputMaybe<Scalars['Time']['input']>;
  /** ISO-8601 */
  start5?: InputMaybe<Scalars['Time']['input']>;
  statisticsEnabled: Scalars['Boolean']['input'];
  taxOffice?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  vip: Scalars['Boolean']['input'];
};

export type DoctorExamImageCount = EntityInterface & {
  __typename?: 'DoctorExamImageCount';
  count: Scalars['Int']['output'];
  doctor?: Maybe<Doctor>;
  id?: Maybe<Scalars['Object']['output']>;
  image?: Maybe<Image>;
};

export type DoctorExamStats = {
  __typename?: 'DoctorExamStats';
  data?: Maybe<Scalars['Object']['output']>;
  doctor?: Maybe<Doctor>;
};

export type DoctorGroupSettings = {
  __typename?: 'DoctorGroupSettings';
  deleted: Scalars['Boolean']['output'];
  doctor: Doctor;
  eopyyPrice?: Maybe<Scalars['Float']['output']>;
  generalPrice?: Maybe<Scalars['Float']['output']>;
  group: ExamGroup;
  id?: Maybe<Scalars['Object']['output']>;
  invoiceDescription?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type DoctorInventory = EntityInterface & {
  __typename?: 'DoctorInventory';
  /** ISO-8601 */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  doctor: Doctor;
  id?: Maybe<Scalars['Object']['output']>;
  inventory: Inventory;
  quantity: Scalars['Int']['output'];
};

export type DoctorInventoryDtoInput = {
  doctorId?: InputMaybe<Scalars['Int']['input']>;
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type DoctorInventoryTotal = EntityInterface & {
  __typename?: 'DoctorInventoryTotal';
  doctor: Doctor;
  id?: Maybe<Scalars['Object']['output']>;
  inventory: Inventory;
  quantity: Scalars['Int']['output'];
};

export type DoctorOverviewDtoInput = {
  otherDebts?: InputMaybe<Scalars['Float']['input']>;
};

export type DoctorReferralStats = {
  __typename?: 'DoctorReferralStats';
  data?: Maybe<Scalars['Object']['output']>;
  doctor?: Maybe<Doctor>;
};

export type DoctorSettingsDtoInput = {
  doctorId?: InputMaybe<Scalars['Int']['input']>;
  eopyyPrice?: InputMaybe<Scalars['Float']['input']>;
  generalPrice?: InputMaybe<Scalars['Float']['input']>;
  invoiceDescription?: InputMaybe<Scalars['String']['input']>;
};

export type EditedInsurance = EntityInterface & {
  __typename?: 'EditedInsurance';
  clinic?: Maybe<Clinic>;
  doctor?: Maybe<Doctor>;
  doctorName?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  eventCreatedAt?: Maybe<Scalars['Date']['output']>;
  group?: Maybe<ExamGroup>;
  groupPathName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  insuranceName?: Maybe<Scalars['String']['output']>;
  oldInsuranceName?: Maybe<Scalars['String']['output']>;
  patientName?: Maybe<Scalars['String']['output']>;
  patientSsn?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  referralDate?: Maybe<Scalars['Date']['output']>;
};

export type EntityInterface = {
  id?: Maybe<Scalars['Object']['output']>;
};

export type Entry_String_ObjectInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Object']['input']>;
};

export type Event = EntityInterface & {
  __typename?: 'Event';
  /** ISO-8601 */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  eventName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  modelData?: Maybe<Scalars['Object']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  newValues?: Maybe<Scalars['Object']['output']>;
  oldValues?: Maybe<Scalars['Object']['output']>;
  recordId?: Maybe<Scalars['String']['output']>;
  schemaName?: Maybe<Scalars['String']['output']>;
  tableName?: Maybe<Scalars['String']['output']>;
  txId?: Maybe<Scalars['BigInteger']['output']>;
  user?: Maybe<UserDetails>;
};

export type EventStatsDto = {
  __typename?: 'EventStatsDto';
  data: Scalars['Object']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type Exam = {
  __typename?: 'Exam';
  adminEmails?: Maybe<Scalars['Int']['output']>;
  adminPrints?: Maybe<Scalars['Int']['output']>;
  adminViews?: Maybe<Scalars['Int']['output']>;
  /** ISO-8601 */
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  approvedBy?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  createdAt?: Maybe<Scalars['Date']['output']>;
  data?: Maybe<Scalars['Object']['output']>;
  deleted: Scalars['Boolean']['output'];
  doctorDeleted: Scalars['Boolean']['output'];
  doctorEmails?: Maybe<Scalars['Int']['output']>;
  doctorPrints?: Maybe<Scalars['Int']['output']>;
  doctorViews?: Maybe<Scalars['Int']['output']>;
  examiners?: Maybe<Array<Maybe<Examiner>>>;
  hasPdfGenerated: Scalars['Boolean']['output'];
  history?: Maybe<Array<Maybe<PatientExamsHistory>>>;
  id?: Maybe<Scalars['Object']['output']>;
  invoiceId?: Maybe<Scalars['Int']['output']>;
  pFlag?: Maybe<PFlagType>;
  referral: Referral;
  template: ExamTemplate;
  tsv?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};


export type ExamHistoryArgs = {
  historyDays?: InputMaybe<Scalars['Int']['input']>;
};

export enum ExamDeliveryMethod {
  Courier = 'COURIER',
  Other = 'OTHER',
  Private = 'PRIVATE'
}

export type ExamDtoInput = {
  /** ISO-8601 */
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  approvedBy?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  data?: InputMaybe<Scalars['Object']['input']>;
  examinerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  pflag?: InputMaybe<PFlagType>;
  referralId?: InputMaybe<Scalars['Int']['input']>;
  templateId?: InputMaybe<Scalars['Int']['input']>;
};

export type ExamGroup = {
  __typename?: 'ExamGroup';
  active: Scalars['Boolean']['output'];
  allowCotesting: Scalars['Boolean']['output'];
  clinicsSettings?: Maybe<Array<Maybe<ExamGroupClinicSettings>>>;
  code?: Maybe<Scalars['String']['output']>;
  counter?: Maybe<Counter>;
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  doctorsSettings?: Maybe<Array<Maybe<DoctorGroupSettings>>>;
  eopyyPrice?: Maybe<Scalars['Float']['output']>;
  examsTemplates?: Maybe<Array<Maybe<ExamTemplate>>>;
  finalEopyyPrice?: Maybe<ExamGroupDataDto_Float>;
  finalInvoiceDescription?: Maybe<ExamGroupDataDto_String>;
  finalPrice?: Maybe<ExamGroupDataDto_Float>;
  generalPrice?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  invoiceDescription?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parent?: Maybe<ExamGroup>;
  pathName?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<Scalars['Object']['output']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};


export type ExamGroupFinalEopyyPriceArgs = {
  doctorId?: InputMaybe<Scalars['Int']['input']>;
};


export type ExamGroupFinalInvoiceDescriptionArgs = {
  doctorId?: InputMaybe<Scalars['Int']['input']>;
};


export type ExamGroupFinalPriceArgs = {
  doctorId?: InputMaybe<Scalars['Int']['input']>;
};

export type ExamGroupClinicSettings = EntityInterface & {
  __typename?: 'ExamGroupClinicSettings';
  clinic?: Maybe<Clinic>;
  examGroup?: Maybe<ExamGroup>;
  examinerIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  id?: Maybe<Scalars['Object']['output']>;
  isoLogo?: Maybe<Image>;
};

export type ExamGroupClinicSettingsDtoInput = {
  clinicId?: InputMaybe<Scalars['Int']['input']>;
  examGroupId?: InputMaybe<Scalars['Int']['input']>;
  examinerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['BigInteger']['input']>;
  isoLogoId?: InputMaybe<Scalars['Int']['input']>;
};

export type ExamGroupDataDto_Float = {
  __typename?: 'ExamGroupDataDto_Float';
  origin?: Maybe<DataOrigin>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type ExamGroupDataDto_String = {
  __typename?: 'ExamGroupDataDto_String';
  origin?: Maybe<DataOrigin>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ExamGroupDtoInput = {
  active: Scalars['Boolean']['input'];
  clinicsSettings?: InputMaybe<Array<InputMaybe<ExamGroupClinicSettingsDtoInput>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eopyyPrice?: InputMaybe<Scalars['Float']['input']>;
  generalPrice?: InputMaybe<Scalars['Float']['input']>;
  invoiceDescription?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

export type ExamGroupInventory = {
  __typename?: 'ExamGroupInventory';
  deleted: Scalars['Boolean']['output'];
  examGroup: ExamGroup;
  id?: Maybe<Scalars['Object']['output']>;
  inventory: Inventory;
  quantity: Scalars['Int']['output'];
  source: InventoryStorage;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ExamGroupInventoryDtoInput = {
  examGroupId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<InventoryStorage>;
};

export type ExamTemplate = {
  __typename?: 'ExamTemplate';
  active: Scalars['Boolean']['output'];
  approval: Scalars['Boolean']['output'];
  clinic?: Maybe<Clinic>;
  data?: Maybe<Scalars['Object']['output']>;
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  group: ExamGroup;
  id?: Maybe<Scalars['Object']['output']>;
  imagesGroup?: Maybe<ImageGroup>;
  important: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  pattern: Pattern;
  tags?: Maybe<Array<Maybe<Tag>>>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ExamTemplateCount = EntityInterface & {
  __typename?: 'ExamTemplateCount';
  count: Scalars['Int']['output'];
  id?: Maybe<Scalars['Object']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ExamTemplateDtoInput = {
  active: Scalars['Boolean']['input'];
  approval: Scalars['Boolean']['input'];
  data?: InputMaybe<Scalars['Object']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  imagesGroupId?: InputMaybe<Scalars['Int']['input']>;
  important: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  patternId?: InputMaybe<Scalars['Int']['input']>;
};

export type ExamWithoutInvoices = EntityInterface & {
  __typename?: 'ExamWithoutInvoices';
  clinic?: Maybe<Clinic>;
  doctorName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  /** ISO-8601 */
  lastInvoiceDate?: Maybe<Scalars['Date']['output']>;
};

export type Examiner = {
  __typename?: 'Examiner';
  active: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  exams?: Maybe<Array<Maybe<Exam>>>;
  id?: Maybe<Scalars['Object']['output']>;
  name: Scalars['String']['output'];
  settings: Array<Maybe<ExaminerSettings>>;
  signature: Image;
  specialty: Scalars['String']['output'];
  ssn?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ExaminerDtoInput = {
  active: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Array<InputMaybe<ExaminerSettingsDtoInput>>>;
  signature?: InputMaybe<ImageDtoInput>;
  specialty?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type ExaminerSettings = EntityInterface & {
  __typename?: 'ExaminerSettings';
  clinic?: Maybe<Clinic>;
  examiner?: Maybe<Examiner>;
  id?: Maybe<Scalars['Object']['output']>;
  quota?: Maybe<Scalars['Int']['output']>;
};

export type ExaminerSettingsDtoInput = {
  clinicId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  quota?: InputMaybe<Scalars['Int']['input']>;
};

export type FileDto = {
  __typename?: 'FileDto';
  content?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  contentType?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
};

export type FilterMetadataInput = {
  field?: InputMaybe<Scalars['String']['input']>;
  matchMode?: InputMaybe<MatchMode>;
  value?: InputMaybe<Scalars['Object']['input']>;
};

export type Image = {
  __typename?: 'Image';
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  doctorCount?: Maybe<Array<Maybe<DoctorExamImageCount>>>;
  fileName: Scalars['String']['output'];
  group: ImageGroup;
  id?: Maybe<Scalars['Object']['output']>;
  name: Scalars['String']['output'];
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ImageDto = {
  __typename?: 'ImageDto';
  fileName?: Maybe<Scalars['String']['output']>;
  full?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  imagesGroupId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type ImageDtoInput = {
  fileName?: InputMaybe<Scalars['String']['input']>;
  full?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  imagesGroupId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type ImageGroup = {
  __typename?: 'ImageGroup';
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  images?: Maybe<Array<Maybe<Image>>>;
  name: Scalars['String']['output'];
  parent?: Maybe<ImageGroup>;
  subtype: ImageGroupSubtype;
  thumbnailImages?: Maybe<Array<Maybe<ImageDto>>>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ImageGroupDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  subtype?: InputMaybe<ImageGroupSubtype>;
};

export type ImageGroupOptionDto = {
  __typename?: 'ImageGroupOptionDto';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<ImageGroupOptionDto>;
};

export enum ImageGroupSubtype {
  ClinicSignature = 'CLINIC_SIGNATURE',
  DoctorAvatar = 'DOCTOR_AVATAR',
  ExaminerSignature = 'EXAMINER_SIGNATURE',
  Exams = 'EXAMS',
  Logos = 'LOGOS',
  Unique = 'UNIQUE',
  UserAvatar = 'USER_AVATAR'
}

export type Insurance = {
  __typename?: 'Insurance';
  deleted: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Object']['output']>;
  name: Scalars['String']['output'];
  settings?: Maybe<Scalars['Object']['output']>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type InsuranceDtoInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Array<InputMaybe<Entry_String_ObjectInput>>>;
};

export type InvalidRecipient = EntityInterface & {
  __typename?: 'InvalidRecipient';
  email: Scalars['String']['output'];
  id?: Maybe<Scalars['Object']['output']>;
  recipientId?: Maybe<Scalars['Int']['output']>;
  recipientType?: Maybe<RecipientType>;
};

export type Inventory = {
  __typename?: 'Inventory';
  clinic: Clinic;
  deleted: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Object']['output']>;
  name: Scalars['String']['output'];
  /** ISO-8601 */
  registrationDate: Scalars['Date']['output'];
  totalQuantity: Scalars['Int']['output'];
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type InventoryDelivery = {
  __typename?: 'InventoryDelivery';
  deleted: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Object']['output']>;
  inventory: Inventory;
  /** ISO-8601 */
  lotExpDate?: Maybe<Scalars['Date']['output']>;
  lotId?: Maybe<Scalars['Int']['output']>;
  quantity: Scalars['Int']['output'];
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type InventoryDeliveryDtoInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  /** ISO-8601 */
  lotExpDate?: InputMaybe<Scalars['Date']['input']>;
  lotId?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type InventoryDtoInput = {
  clinicId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  registrationDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum InventoryStorage {
  Doc = 'DOC',
  Lab = 'LAB'
}

export type Invoice = AadeSource & {
  __typename?: 'Invoice';
  aadeResponse?: Maybe<Scalars['Object']['output']>;
  aadeStatus?: Maybe<AadeSyncStatus>;
  /** ISO-8601 */
  annulledAt?: Maybe<Scalars['DateTime']['output']>;
  annulledBy?: Maybe<Scalars['String']['output']>;
  clinic: Clinic;
  /** ISO-8601 */
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['Object']['output']>;
  deleted: Scalars['Boolean']['output'];
  doctor: Doctor;
  id?: Maybe<Scalars['Object']['output']>;
  num?: Maybe<Scalars['String']['output']>;
  rows?: Maybe<Array<Maybe<InvoiceRow>>>;
  sent: Scalars['Int']['output'];
  status?: Maybe<InvoiceStatus>;
  template: InvoiceTemplate;
  total: Scalars['Float']['output'];
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userFullName?: Maybe<Scalars['String']['output']>;
};

export type InvoiceDtoInput = {
  /** ISO-8601 */
  annulledAt?: InputMaybe<Scalars['DateTime']['input']>;
  annulledBy?: InputMaybe<Scalars['String']['input']>;
  clinicId?: InputMaybe<Scalars['Int']['input']>;
  /** ISO-8601 */
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  data?: InputMaybe<Scalars['Object']['input']>;
  doctorId?: InputMaybe<Scalars['Int']['input']>;
  num?: InputMaybe<Scalars['String']['input']>;
  rows?: InputMaybe<Array<InputMaybe<InvoiceRowDtoInput>>>;
  status?: InputMaybe<InvoiceStatus>;
  templateId?: InputMaybe<Scalars['Int']['input']>;
};

export type InvoiceRow = EntityInterface & {
  __typename?: 'InvoiceRow';
  article?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
  id?: Maybe<Scalars['Object']['output']>;
  invoice: Invoice;
  price: Scalars['Float']['output'];
};

export type InvoiceRowDtoInput = {
  article?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  examId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  price: Scalars['Float']['input'];
};

export enum InvoiceStatus {
  Annulled = 'ANNULLED',
  Closed = 'CLOSED',
  Opened = 'OPENED'
}

export type InvoiceTemplate = {
  __typename?: 'InvoiceTemplate';
  counter?: Maybe<Counter>;
  data?: Maybe<Scalars['Object']['output']>;
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  name: Scalars['String']['output'];
  pattern: Pattern;
  type: InvoiceTemplateType;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export enum InvoiceTemplateType {
  Exams = 'EXAMS',
  General = 'GENERAL'
}

export enum MatchMode {
  And = 'AND',
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  ContainsCi = 'CONTAINS_CI',
  Custom = 'CUSTOM',
  Empty = 'EMPTY',
  EndsWith = 'ENDS_WITH',
  EndsWithCi = 'ENDS_WITH_CI',
  Equals = 'EQUALS',
  EqualsCi = 'EQUALS_CI',
  EqualsOrNull = 'EQUALS_OR_NULL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualsTo = 'GREATER_THAN_OR_EQUALS_TO',
  In = 'IN',
  InOrEmpty = 'IN_OR_EMPTY',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  JsonExists = 'JSON_EXISTS',
  LessThan = 'LESS_THAN',
  LessThanOrEqualsTo = 'LESS_THAN_OR_EQUALS_TO',
  Like = 'LIKE',
  LikeCi = 'LIKE_CI',
  NotContains = 'NOT_CONTAINS',
  NotEmpty = 'NOT_EMPTY',
  NotEquals = 'NOT_EQUALS',
  Or = 'OR',
  StartsWith = 'STARTS_WITH',
  StartsWithCi = 'STARTS_WITH_CI'
}

export type MergeDoctorDtoInput = {
  clinicId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  examsDeliveryMethod?: InputMaybe<ExamDeliveryMethod>;
  examsDeliveryMethodComment?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentMethodComment?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  resultsDeliveryMethod?: InputMaybe<ResultDeliveryMethod>;
  resultsDeliveryMethodComment?: InputMaybe<Scalars['String']['input']>;
  secretaryName?: InputMaybe<Scalars['String']['input']>;
  secretaryPhone?: InputMaybe<Scalars['String']['input']>;
  specialty?: InputMaybe<Scalars['String']['input']>;
  taxOffice?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MergePatientDtoInput = {
  /** ISO-8601 */
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  clinicId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type Message = {
  __typename?: 'Message';
  /** ISO-8601 */
  activeFrom?: Maybe<Scalars['Date']['output']>;
  /** ISO-8601 */
  activeTo?: Maybe<Scalars['Date']['output']>;
  content: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  doctor?: Maybe<Doctor>;
  id?: Maybe<Scalars['Object']['output']>;
  openedCount?: Maybe<Scalars['Int']['output']>;
  reads?: Maybe<Array<Maybe<MessageEvent>>>;
  readsCount?: Maybe<Scalars['Int']['output']>;
  readsJson?: Maybe<Scalars['Object']['output']>;
  title: Scalars['String']['output'];
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type MessageDtoInput = {
  /** ISO-8601 */
  activeFrom?: InputMaybe<Scalars['Date']['input']>;
  /** ISO-8601 */
  activeTo?: InputMaybe<Scalars['Date']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  doctorId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MessageEvent = {
  __typename?: 'MessageEvent';
  doctor?: Maybe<Doctor>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Message>;
  /** ISO-8601 */
  openedAt?: Maybe<Scalars['DateTime']['output']>;
  /** ISO-8601 */
  readAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  AddExamGroupInventory?: Maybe<ExamGroupInventory>;
  BulkApprove?: Maybe<Response>;
  ChangeExamTemplateGroup?: Maybe<Array<Maybe<ExamTemplate>>>;
  DeleteDoctor?: Maybe<Response>;
  DeleteDoctorInventory?: Maybe<Response>;
  DeleteExam?: Maybe<Response>;
  DeleteExamGroupInventory?: Maybe<Response>;
  DeleteImage?: Maybe<Response>;
  DeleteImageGroup?: Maybe<Response>;
  DeleteInventory?: Maybe<Response>;
  DeleteMessage?: Maybe<Response>;
  DeleteOrder?: Maybe<Response>;
  DeletePatient?: Maybe<Response>;
  DeletePattern?: Maybe<Response>;
  DeleteReceipt?: Maybe<Response>;
  DeleteReferral?: Maybe<Response>;
  DeleteReferralGroup?: Maybe<Response>;
  DeleteReferralRequest?: Maybe<Response>;
  DoctorAddTag?: Maybe<Doctor>;
  DoctorRemoveTag?: Maybe<Doctor>;
  ExamGroupAddTag?: Maybe<ExamGroup>;
  ExamGroupRemoveTag?: Maybe<ExamGroup>;
  ExamTemplateAddTag?: Maybe<ExamTemplate>;
  ExamTemplateRemoveTag?: Maybe<ExamTemplate>;
  GetExamPdf?: Maybe<FileDto>;
  MergeDoctor?: Maybe<Doctor>;
  MergePatient?: Maybe<Patient>;
  RemoveReferralGrouping?: Maybe<Referral>;
  SaveAllReceipts?: Maybe<Array<Maybe<Receipt>>>;
  SaveAllReferrals?: Maybe<Array<Maybe<Referral>>>;
  SaveClinic?: Maybe<Clinic>;
  SaveDoctor?: Maybe<Doctor>;
  SaveDoctorInventory?: Maybe<DoctorInventory>;
  SaveDoctorOverview?: Maybe<Doctor>;
  SaveExam?: Maybe<Exam>;
  SaveExamGroup?: Maybe<ExamGroup>;
  SaveExamGroupDoctorSettings?: Maybe<ExamGroup>;
  SaveExamPdf?: Maybe<Response>;
  SaveExamTemplate?: Maybe<ExamTemplate>;
  SaveExaminer?: Maybe<Examiner>;
  SaveImage?: Maybe<ImageDto>;
  SaveImageGroup?: Maybe<ImageGroup>;
  SaveInsurance?: Maybe<Insurance>;
  SaveInventory?: Maybe<Inventory>;
  SaveInventoryDelivery?: Maybe<InventoryDelivery>;
  SaveInvoice?: Maybe<Invoice>;
  SaveMessage?: Maybe<Message>;
  SaveOrder?: Maybe<Order>;
  SavePatient?: Maybe<Patient>;
  SavePattern?: Maybe<Pattern>;
  SaveReceipt?: Maybe<Receipt>;
  SaveReceiptField?: Maybe<Receipt>;
  SaveReceiptGroup?: Maybe<ReceiptGroup>;
  SaveReceiptTemplate?: Maybe<ReceiptTemplate>;
  SaveReceiptVisible?: Maybe<Receipt>;
  SaveReferral?: Maybe<Referral>;
  SaveReferralGroupVisible?: Maybe<ReferralGroup>;
  SaveReferralGrouping?: Maybe<ReferralGroup>;
  SaveReferralRequest?: Maybe<ReferralRequest>;
  SaveReferralVisible?: Maybe<Referral>;
  SaveTag?: Maybe<Tag>;
  SendInvoiceToAade?: Maybe<Invoice>;
  SendMail?: Maybe<Response>;
  SendReceiptToAade?: Maybe<Receipt>;
  SetExamAdminAction?: Maybe<Response>;
  SetNumbers?: Maybe<Response>;
  Settings?: Maybe<Response>;
  UndeleteForDoctor?: Maybe<Exam>;
};


/** Mutation root */
export type MutationAddExamGroupInventoryArgs = {
  dto?: InputMaybe<ExamGroupInventoryDtoInput>;
};


/** Mutation root */
export type MutationBulkApproveArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


/** Mutation root */
export type MutationChangeExamTemplateGroupArgs = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  templateIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


/** Mutation root */
export type MutationDeleteDoctorArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeleteDoctorInventoryArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeleteExamArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeleteExamGroupInventoryArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeleteImageArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeleteImageGroupArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeleteInventoryArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeleteMessageArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeleteOrderArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeletePatientArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeletePatternArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeleteReceiptArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeleteReferralArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDeleteReferralGroupArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
};


/** Mutation root */
export type MutationDeleteReferralRequestArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDoctorAddTagArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationDoctorRemoveTagArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationExamGroupAddTagArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationExamGroupRemoveTagArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationExamTemplateAddTagArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationExamTemplateRemoveTagArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationGetExamPdfArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  registerView?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutation root */
export type MutationMergeDoctorArgs = {
  dto?: InputMaybe<MergeDoctorDtoInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationMergePatientArgs = {
  dto?: InputMaybe<MergePatientDtoInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationRemoveReferralGroupingArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveAllReceiptsArgs = {
  dtos?: InputMaybe<Array<InputMaybe<ReceiptDtoInput>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveAllReferralsArgs = {
  dtos?: InputMaybe<Array<InputMaybe<ReferralDtoInput>>>;
  mainId?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveClinicArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  incomeClinic?: InputMaybe<ClinicDtoInput>;
};


/** Mutation root */
export type MutationSaveDoctorArgs = {
  dto?: InputMaybe<DoctorDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveDoctorInventoryArgs = {
  dto?: InputMaybe<DoctorInventoryDtoInput>;
};


/** Mutation root */
export type MutationSaveDoctorOverviewArgs = {
  dto?: InputMaybe<DoctorOverviewDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveExamArgs = {
  dto?: InputMaybe<ExamDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveExamGroupArgs = {
  dto?: InputMaybe<ExamGroupDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveExamGroupDoctorSettingsArgs = {
  dto?: InputMaybe<DoctorSettingsDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveExamPdfArgs = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveExamTemplateArgs = {
  dto?: InputMaybe<ExamTemplateDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveExaminerArgs = {
  examiner?: InputMaybe<ExaminerDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveImageArgs = {
  dto?: InputMaybe<ImageDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveImageGroupArgs = {
  dto?: InputMaybe<ImageGroupDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveInsuranceArgs = {
  dto?: InputMaybe<InsuranceDtoInput>;
};


/** Mutation root */
export type MutationSaveInventoryArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  inventory?: InputMaybe<InventoryDtoInput>;
};


/** Mutation root */
export type MutationSaveInventoryDeliveryArgs = {
  dto?: InputMaybe<InventoryDeliveryDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveInvoiceArgs = {
  dto?: InputMaybe<InvoiceDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveMessageArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  incomeMsg?: InputMaybe<MessageDtoInput>;
};


/** Mutation root */
export type MutationSaveOrderArgs = {
  dto?: InputMaybe<OrderDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSavePatientArgs = {
  dto?: InputMaybe<PatientDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSavePatternArgs = {
  dto?: InputMaybe<PatternDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveReceiptArgs = {
  dto?: InputMaybe<ReceiptDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveReceiptFieldArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveReceiptGroupArgs = {
  dto?: InputMaybe<ReceiptGroupDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveReceiptTemplateArgs = {
  dto?: InputMaybe<ReceiptTemplateDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveReceiptVisibleArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['Boolean']['input'];
};


/** Mutation root */
export type MutationSaveReferralArgs = {
  dto?: InputMaybe<ReferralDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainId?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveReferralGroupVisibleArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Boolean']['input'];
};


/** Mutation root */
export type MutationSaveReferralGroupingArgs = {
  mainId?: InputMaybe<Scalars['Int']['input']>;
  otherIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


/** Mutation root */
export type MutationSaveReferralRequestArgs = {
  dto?: InputMaybe<ReferralRequestDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSaveReferralVisibleArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['Boolean']['input'];
};


/** Mutation root */
export type MutationSaveTagArgs = {
  dto?: InputMaybe<TagDtoInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSendInvoiceToAadeArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSendMailArgs = {
  data?: InputMaybe<SendDataInput>;
};


/** Mutation root */
export type MutationSendReceiptToAadeArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSetExamAdminActionArgs = {
  action?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSetNumbersArgs = {
  orderId?: InputMaybe<Scalars['Int']['input']>;
};


/** Mutation root */
export type MutationSettingsArgs = {
  dto?: InputMaybe<SettingsDtoInput>;
};


/** Mutation root */
export type MutationUndeleteForDoctorArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type Order = {
  __typename?: 'Order';
  deleted: Scalars['Boolean']['output'];
  doctor: Doctor;
  fullNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  /** ISO-8601 */
  issueDate: Scalars['Date']['output'];
  notes: Scalars['String']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  /** ISO-8601 */
  packageArrival: Scalars['Date']['output'];
  receivedMoney?: Maybe<Scalars['Float']['output']>;
  referralTypes?: Maybe<Scalars['String']['output']>;
  referrals?: Maybe<Array<Maybe<Referral>>>;
  tsv?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type OrderDtoInput = {
  doctorId?: InputMaybe<Scalars['Int']['input']>;
  /** ISO-8601 */
  issueDate?: InputMaybe<Scalars['Date']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  packageArrival?: InputMaybe<Scalars['Date']['input']>;
  receivedMoney?: InputMaybe<Scalars['Float']['input']>;
  referrals?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum PFlagType {
  Inherited = 'INHERITED',
  Manual = 'MANUAL',
  None = 'NONE'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  displayXtoYofZ?: Maybe<Scalars['String']['output']>;
  hasNext: Scalars['Boolean']['output'];
  hasPrev: Scalars['Boolean']['output'];
  pageIndex: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPageCount: Scalars['Int']['output'];
};

export type PagedList_Clinic = {
  __typename?: 'PagedList_Clinic';
  items?: Maybe<Array<Maybe<Clinic>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Doctor = {
  __typename?: 'PagedList_Doctor';
  items?: Maybe<Array<Maybe<Doctor>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_DoctorInventory = {
  __typename?: 'PagedList_DoctorInventory';
  items?: Maybe<Array<Maybe<DoctorInventory>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_DoctorInventoryTotal = {
  __typename?: 'PagedList_DoctorInventoryTotal';
  items?: Maybe<Array<Maybe<DoctorInventoryTotal>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_EditedInsurance = {
  __typename?: 'PagedList_EditedInsurance';
  items?: Maybe<Array<Maybe<EditedInsurance>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Event = {
  __typename?: 'PagedList_Event';
  items?: Maybe<Array<Maybe<Event>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Exam = {
  __typename?: 'PagedList_Exam';
  items?: Maybe<Array<Maybe<Exam>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_ExamTemplate = {
  __typename?: 'PagedList_ExamTemplate';
  items?: Maybe<Array<Maybe<ExamTemplate>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_ExamWithoutInvoices = {
  __typename?: 'PagedList_ExamWithoutInvoices';
  items?: Maybe<Array<Maybe<ExamWithoutInvoices>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Examiner = {
  __typename?: 'PagedList_Examiner';
  items?: Maybe<Array<Maybe<Examiner>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Inventory = {
  __typename?: 'PagedList_Inventory';
  items?: Maybe<Array<Maybe<Inventory>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_InventoryDelivery = {
  __typename?: 'PagedList_InventoryDelivery';
  items?: Maybe<Array<Maybe<InventoryDelivery>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Invoice = {
  __typename?: 'PagedList_Invoice';
  items?: Maybe<Array<Maybe<Invoice>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_InvoiceTemplate = {
  __typename?: 'PagedList_InvoiceTemplate';
  items?: Maybe<Array<Maybe<InvoiceTemplate>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Message = {
  __typename?: 'PagedList_Message';
  items?: Maybe<Array<Maybe<Message>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Order = {
  __typename?: 'PagedList_Order';
  items?: Maybe<Array<Maybe<Order>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Patient = {
  __typename?: 'PagedList_Patient';
  items?: Maybe<Array<Maybe<Patient>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Pattern = {
  __typename?: 'PagedList_Pattern';
  items?: Maybe<Array<Maybe<Pattern>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Receipt = {
  __typename?: 'PagedList_Receipt';
  items?: Maybe<Array<Maybe<Receipt>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_ReceiptGroup = {
  __typename?: 'PagedList_ReceiptGroup';
  items?: Maybe<Array<Maybe<ReceiptGroup>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_ReceiptTemplate = {
  __typename?: 'PagedList_ReceiptTemplate';
  items?: Maybe<Array<Maybe<ReceiptTemplate>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Referral = {
  __typename?: 'PagedList_Referral';
  items?: Maybe<Array<Maybe<Referral>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_ReferralRequest = {
  __typename?: 'PagedList_ReferralRequest';
  items?: Maybe<Array<Maybe<ReferralRequest>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_ReportInventoryByDoctor = {
  __typename?: 'PagedList_ReportInventoryByDoctor';
  items?: Maybe<Array<Maybe<ReportInventoryByDoctor>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PagedList_Tag = {
  __typename?: 'PagedList_Tag';
  items?: Maybe<Array<Maybe<Tag>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Patient = {
  __typename?: 'Patient';
  /** ISO-8601 */
  birthDate?: Maybe<Scalars['Date']['output']>;
  cache?: Maybe<Scalars['Object']['output']>;
  clinic: Clinic;
  deleted: Scalars['Boolean']['output'];
  doctors?: Maybe<Array<Maybe<Doctor>>>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullAddress?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  referrals?: Maybe<Array<Maybe<Referral>>>;
  sourcedCache?: Maybe<Scalars['Object']['output']>;
  ssn: Scalars['String']['output'];
  tsv?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type PatientDtoInput = {
  /** ISO-8601 */
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  clinicId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fullAddress?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type PatientExamsHistory = {
  __typename?: 'PatientExamsHistory';
  examId?: Maybe<Scalars['Int']['output']>;
  examResult?: Maybe<Scalars['String']['output']>;
  highlight: Scalars['Boolean']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  recent: Scalars['Boolean']['output'];
  /** ISO-8601 */
  referralDate?: Maybe<Scalars['Date']['output']>;
  referralGroupPathName?: Maybe<Scalars['String']['output']>;
};

export type Pattern = {
  __typename?: 'Pattern';
  content?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  model?: Maybe<PatternModel>;
  name: Scalars['String']['output'];
  type: PatternType;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type PatternDtoInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<PatternModel>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PatternType>;
};

export enum PatternModel {
  All = 'ALL',
  Exam = 'EXAM',
  ExamEmail = 'EXAM_EMAIL',
  Invoice = 'INVOICE',
  InvoiceEmail = 'INVOICE_EMAIL',
  Receipt = 'RECEIPT',
  ReceiptEmail = 'RECEIPT_EMAIL',
  System = 'SYSTEM'
}

export enum PatternType {
  Conclusion = 'CONCLUSION',
  Description = 'DESCRIPTION',
  DoctorData = 'DOCTOR_DATA',
  FiscalData = 'FISCAL_DATA',
  Footer = 'FOOTER',
  Header = 'HEADER',
  Images = 'IMAGES',
  InvoiceData = 'INVOICE_DATA',
  Main = 'MAIN',
  PatientData = 'PATIENT_DATA',
  Recommendation = 'RECOMMENDATION',
  Result = 'RESULT',
  Spacer = 'SPACER',
  Title = 'TITLE'
}

export enum PaymentMethod {
  Bank = 'BANK',
  Cash = 'CASH',
  Other = 'OTHER'
}

export type Placeholder = {
  __typename?: 'Placeholder';
  deleted: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Object']['output']>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Maybe<PlaceholderValue>>>;
};

export type PlaceholderValue = {
  __typename?: 'PlaceholderValue';
  deleted: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Object']['output']>;
  label: Scalars['String']['output'];
  placeholder: Placeholder;
  position: Scalars['Int']['output'];
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  AcInventoryList?: Maybe<Array<Maybe<Inventory>>>;
  Clinic?: Maybe<Clinic>;
  ClinicList?: Maybe<Array<Maybe<Clinic>>>;
  ClinicPagedList?: Maybe<PagedList_Clinic>;
  Doctor?: Maybe<Doctor>;
  DoctorByVatNumber?: Maybe<Array<Maybe<Doctor>>>;
  DoctorFinancialData?: Maybe<DoctorDto>;
  DoctorInventoryPagedList?: Maybe<PagedList_DoctorInventory>;
  DoctorInventoryTotalsPagedList?: Maybe<PagedList_DoctorInventoryTotal>;
  DoctorOverview?: Maybe<DoctorDashboard>;
  DoctorPagedList?: Maybe<PagedList_Doctor>;
  DoctorSubList?: Maybe<Array<Maybe<Doctor>>>;
  EditedInsurancePagedList?: Maybe<PagedList_EditedInsurance>;
  EventPagedList?: Maybe<PagedList_Event>;
  Exam?: Maybe<Exam>;
  ExamByDoctorInsurancePagedList?: Maybe<PagedList_Exam>;
  ExamGroup?: Maybe<ExamGroup>;
  ExamGroupInventoryList?: Maybe<Array<Maybe<ExamGroupInventory>>>;
  ExamGroupList?: Maybe<Array<Maybe<ExamGroup>>>;
  ExamList?: Maybe<Array<Maybe<Exam>>>;
  ExamPagedList?: Maybe<PagedList_Exam>;
  ExamTemplate?: Maybe<ExamTemplate>;
  ExamTemplateCountByDoctorList?: Maybe<Array<Maybe<ExamTemplateCount>>>;
  ExamTemplateList?: Maybe<Array<Maybe<ExamTemplate>>>;
  ExamTemplatePagedList?: Maybe<PagedList_ExamTemplate>;
  Examiner?: Maybe<Examiner>;
  ExaminerList?: Maybe<Array<Maybe<Examiner>>>;
  ExaminerPagedList?: Maybe<PagedList_Examiner>;
  ExaminersSubList?: Maybe<Array<Maybe<Examiner>>>;
  ExamsByPeriodIsoList?: Maybe<Array<Maybe<Exam>>>;
  ExamsByPeriodList?: Maybe<Array<Maybe<Referral>>>;
  ExamsCountByDoctorPagedList?: Maybe<StatsPagedList_DoctorExamStats>;
  ExamsWithoutInvoicePagedList?: Maybe<PagedList_ExamWithoutInvoices>;
  GeneratePdf?: Maybe<FileDto>;
  GetClinicSettingsByClinicAndEg?: Maybe<ExamGroupClinicSettings>;
  HiddenReferralPagedList?: Maybe<PagedList_Referral>;
  ImageContent?: Maybe<ImageDto>;
  ImageGroup?: Maybe<ImageGroup>;
  ImageGroupList?: Maybe<Array<Maybe<ImageGroup>>>;
  InsuranceList?: Maybe<Array<Maybe<Insurance>>>;
  Inventory?: Maybe<Inventory>;
  InventoryByDoctorPagedList?: Maybe<PagedList_ReportInventoryByDoctor>;
  InventoryDeliveriesPagedList?: Maybe<PagedList_InventoryDelivery>;
  InventoryList?: Maybe<Array<Maybe<Inventory>>>;
  InventoryPagedList?: Maybe<PagedList_Inventory>;
  Invoice?: Maybe<Invoice>;
  InvoiceByPeriodPagedList?: Maybe<PagedList_Invoice>;
  InvoicePagedList?: Maybe<PagedList_Invoice>;
  InvoiceTemplate?: Maybe<InvoiceTemplate>;
  InvoiceTemplateList?: Maybe<Array<Maybe<InvoiceTemplate>>>;
  InvoiceTemplatePagedList?: Maybe<PagedList_InvoiceTemplate>;
  Message?: Maybe<Message>;
  MessagePagedList?: Maybe<PagedList_Message>;
  Order?: Maybe<Order>;
  OrderList?: Maybe<Array<Maybe<Order>>>;
  OrderPagedList?: Maybe<PagedList_Order>;
  Patient?: Maybe<Patient>;
  PatientExams?: Maybe<Array<Maybe<Exam>>>;
  PatientPagedList?: Maybe<PagedList_Patient>;
  PatientSubList?: Maybe<Array<Maybe<Patient>>>;
  Pattern?: Maybe<Pattern>;
  PatternList?: Maybe<Array<Maybe<Pattern>>>;
  PatternsList?: Maybe<Array<Maybe<Pattern>>>;
  PatternsPagedList?: Maybe<PagedList_Pattern>;
  Placeholder?: Maybe<Placeholder>;
  ReadByDoctors?: Maybe<Array<Maybe<MessageEvent>>>;
  Receipt?: Maybe<Receipt>;
  ReceiptGroup?: Maybe<ReceiptGroup>;
  ReceiptGroupPagedList?: Maybe<PagedList_ReceiptGroup>;
  ReceiptGroupsList?: Maybe<Array<Maybe<ReceiptGroup>>>;
  ReceiptList?: Maybe<Array<Maybe<Receipt>>>;
  ReceiptPagedList?: Maybe<PagedList_Receipt>;
  ReceiptTemplate?: Maybe<ReceiptTemplate>;
  ReceiptTemplateList?: Maybe<Array<Maybe<ReceiptTemplate>>>;
  ReceiptTemplatePagedList?: Maybe<PagedList_ReceiptTemplate>;
  ReceiptsByReferral?: Maybe<Array<Maybe<Receipt>>>;
  RecordEventList?: Maybe<Array<Maybe<Event>>>;
  Referral?: Maybe<Referral>;
  ReferralByDoctorInsurancePagedList?: Maybe<PagedList_Referral>;
  ReferralList?: Maybe<Array<Maybe<Referral>>>;
  ReferralPagedList?: Maybe<PagedList_Referral>;
  ReferralRequest?: Maybe<ReferralRequest>;
  ReferralRequestPagedList?: Maybe<PagedList_ReferralRequest>;
  ReferralWithoutExamPagedList?: Maybe<PagedList_Referral>;
  ReferralsByPatient?: Maybe<Array<Maybe<Referral>>>;
  ReferralsCountByDoctorPagedList?: Maybe<StatsPagedList_DoctorReferralStats>;
  ReferralsUnknownInsuranceList?: Maybe<Array<Maybe<Referral>>>;
  Settings?: Maybe<Settings>;
  SubtypeImageGroupList?: Maybe<Array<Maybe<ImageGroupOptionDto>>>;
  SuggestExamImages?: Maybe<Array<Maybe<ImageDto>>>;
  Tag?: Maybe<Tag>;
  TagList?: Maybe<Array<Maybe<Tag>>>;
  TagPagedList?: Maybe<PagedList_Tag>;
  UserActivitySummary?: Maybe<Array<Maybe<EventStatsDto>>>;
  UserDashboard?: Maybe<UserDashboard>;
};


/** Query root */
export type QueryAcInventoryListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryClinicArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryClinicListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryClinicPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryDoctorArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryDoctorByVatNumberArgs = {
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};


/** Query root */
export type QueryDoctorFinancialDataArgs = {
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};


/** Query root */
export type QueryDoctorInventoryPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryDoctorInventoryTotalsPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryDoctorOverviewArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryDoctorPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryDoctorSubListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryEditedInsurancePagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryEventPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExamArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryExamByDoctorInsurancePagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExamGroupArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryExamGroupInventoryListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExamGroupListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExamListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExamPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExamTemplateArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryExamTemplateCountByDoctorListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExamTemplateListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExamTemplatePagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExaminerArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryExaminerListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExaminerPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExaminersSubListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExamsByPeriodIsoListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExamsByPeriodListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExamsCountByDoctorPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryExamsWithoutInvoicePagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryGeneratePdfArgs = {
  content?: InputMaybe<Scalars['String']['input']>;
};


/** Query root */
export type QueryGetClinicSettingsByClinicAndEgArgs = {
  clinicId?: InputMaybe<Scalars['Int']['input']>;
  examGroupId?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryHiddenReferralPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryImageContentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryImageGroupArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryImageGroupListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryInsuranceListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryInventoryArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryInventoryByDoctorPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryInventoryDeliveriesPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryInventoryListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryInventoryPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryInvoiceArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryInvoiceByPeriodPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryInvoicePagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryInvoiceTemplateArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryInvoiceTemplateListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryInvoiceTemplatePagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryMessageArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryMessagePagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryOrderListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryOrderPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryPatientArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryPatientExamsArgs = {
  patientId?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryPatientPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryPatientSubListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryPatternArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryPatternListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryPatternsListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryPatternsPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryPlaceholderArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


/** Query root */
export type QueryReadByDoctorsArgs = {
  msgId?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryReceiptArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryReceiptGroupArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryReceiptGroupPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryReceiptGroupsListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryReceiptListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryReceiptPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryReceiptTemplateArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryReceiptTemplateListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryReceiptTemplatePagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryReceiptsByReferralArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryRecordEventListArgs = {
  recordId?: InputMaybe<Scalars['String']['input']>;
  tableName?: InputMaybe<Scalars['String']['input']>;
};


/** Query root */
export type QueryReferralArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryReferralByDoctorInsurancePagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryReferralListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryReferralPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryReferralRequestArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryReferralRequestPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryReferralWithoutExamPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryReferralsByPatientArgs = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryReferralsCountByDoctorPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryReferralsUnknownInsuranceListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QuerySettingsArgs = {
  context?: InputMaybe<Scalars['String']['input']>;
};


/** Query root */
export type QuerySubtypeImageGroupListArgs = {
  subtype: ImageGroupSubtype;
};


/** Query root */
export type QuerySuggestExamImagesArgs = {
  doctorId?: InputMaybe<Scalars['Int']['input']>;
  imagesGroupId?: InputMaybe<Scalars['Int']['input']>;
  limit: Scalars['Int']['input'];
};


/** Query root */
export type QueryTagArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryTagListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryTagPagedListArgs = {
  params?: InputMaybe<BaseParamsInput>;
};


/** Query root */
export type QueryUserActivitySummaryArgs = {
  params?: InputMaybe<BaseParamsInput>;
};

export type Receipt = AadeSource & {
  __typename?: 'Receipt';
  aadeResponse?: Maybe<Scalars['Object']['output']>;
  aadeStatus?: Maybe<AadeSyncStatus>;
  clinic?: Maybe<Clinic>;
  /** ISO-8601 */
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['Object']['output']>;
  deleted: Scalars['Boolean']['output'];
  doctorName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  num?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  patientName?: Maybe<Scalars['String']['output']>;
  referral?: Maybe<Referral>;
  status?: Maybe<ReceiptStatus>;
  template: ReceiptTemplate;
  total?: Maybe<Scalars['Float']['output']>;
  typical: Scalars['Boolean']['output'];
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  visible: Scalars['Boolean']['output'];
};

export type ReceiptDtoInput = {
  clinicId?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['Object']['input']>;
  examinersIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  referralId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ReceiptStatus>;
  templateId?: InputMaybe<Scalars['Int']['input']>;
  typical: Scalars['Boolean']['input'];
  visible: Scalars['Boolean']['input'];
};

export type ReceiptGroup = {
  __typename?: 'ReceiptGroup';
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  multiIssue: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  permissions?: Maybe<Scalars['Object']['output']>;
  templates?: Maybe<Array<Maybe<ReceiptTemplate>>>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ReceiptGroupDtoInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  multiIssue: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Scalars['Object']['input']>;
  templates?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum ReceiptStatus {
  Annulled = 'ANNULLED',
  Closed = 'CLOSED',
  Opened = 'OPENED'
}

export type ReceiptTemplate = {
  __typename?: 'ReceiptTemplate';
  active?: Maybe<Scalars['Boolean']['output']>;
  clinic?: Maybe<Clinic>;
  data?: Maybe<Scalars['Object']['output']>;
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  examsGroup?: Maybe<ExamGroup>;
  groups?: Maybe<Array<Maybe<ReceiptGroup>>>;
  id?: Maybe<Scalars['Object']['output']>;
  imagesGroup?: Maybe<ImageGroup>;
  name?: Maybe<Scalars['String']['output']>;
  pattern?: Maybe<Pattern>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ReceiptTemplateDtoInput = {
  clinicId?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['Object']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  patternId?: InputMaybe<Scalars['Int']['input']>;
  templateId?: InputMaybe<Scalars['Int']['input']>;
};

export enum RecipientType {
  Doctor = 'DOCTOR',
  Other = 'OTHER',
  Patient = 'PATIENT'
}

export type Referral = {
  __typename?: 'Referral';
  clinic: Clinic;
  clinicSettings?: Maybe<ExamGroupClinicSettings>;
  data?: Maybe<Scalars['Object']['output']>;
  /** ISO-8601 */
  date: Scalars['Date']['output'];
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  doctor: Doctor;
  emergency: Scalars['Boolean']['output'];
  examCount?: Maybe<Scalars['Int']['output']>;
  exams?: Maybe<Array<Maybe<Exam>>>;
  group: ExamGroup;
  groupPathName?: Maybe<Scalars['String']['output']>;
  groupProtocolIds?: Maybe<Scalars['String']['output']>;
  grouping?: Maybe<ReferralGroup>;
  history?: Maybe<Array<Maybe<PatientExamsHistory>>>;
  id?: Maybe<Scalars['Object']['output']>;
  insurance: Insurance;
  labId?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  lastMenstruation?: Maybe<Scalars['Date']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notesViewed: Scalars['Boolean']['output'];
  /** ISO-8601 */
  notesVisibleAfter?: Maybe<Scalars['Date']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Order>;
  pFlag?: Maybe<PFlagType>;
  patient: Patient;
  protocolId?: Maybe<Scalars['String']['output']>;
  receipts?: Maybe<Array<Maybe<Receipt>>>;
  reportDescription?: Maybe<Scalars['String']['output']>;
  restrictedVisibility: Scalars['Boolean']['output'];
  restrictedVisibilityGrouping: Scalars['Boolean']['output'];
  /** ISO-8601 */
  sampleDate: Scalars['Date']['output'];
  shortProtocolId?: Maybe<Scalars['String']['output']>;
  tsv?: Maybe<Scalars['String']['output']>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  visible: Scalars['Boolean']['output'];
  year?: Maybe<Scalars['Int']['output']>;
};


export type ReferralHistoryArgs = {
  historyDays?: InputMaybe<Scalars['Int']['input']>;
};

export type ReferralDtoInput = {
  clinicId?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['Object']['input']>;
  /** ISO-8601 */
  date?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  doctorId?: InputMaybe<Scalars['Int']['input']>;
  emergency: Scalars['Boolean']['input'];
  examinersIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  insuranceId?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  lastMenstruation?: InputMaybe<Scalars['Date']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  notesViewed: Scalars['Boolean']['input'];
  /** ISO-8601 */
  notesVisibleAfter?: InputMaybe<Scalars['Date']['input']>;
  orderId?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['Int']['input']>;
  pflag?: InputMaybe<PFlagType>;
  /** ISO-8601 */
  sampleDate?: InputMaybe<Scalars['Date']['input']>;
  visible: Scalars['Boolean']['input'];
};

export type ReferralGroup = EntityInterface & {
  __typename?: 'ReferralGroup';
  id?: Maybe<Scalars['Object']['output']>;
  /** ISO-8601 */
  maxDate?: Maybe<Scalars['Date']['output']>;
  referrals?: Maybe<Array<Maybe<Referral>>>;
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ReferralRequest = EntityInterface & {
  __typename?: 'ReferralRequest';
  /** ISO-8601 */
  birthDate?: Maybe<Scalars['Date']['output']>;
  deleted: Scalars['Boolean']['output'];
  doctor: Doctor;
  examGroup: ExamGroup;
  firstName: Scalars['String']['output'];
  id?: Maybe<Scalars['Object']['output']>;
  /** ISO-8601 */
  lastMenstruation?: Maybe<Scalars['Date']['output']>;
  lastName: Scalars['String']['output'];
  patient?: Maybe<Patient>;
  ssn: Scalars['String']['output'];
};

export type ReferralRequestDtoInput = {
  /** ISO-8601 */
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  doctorId?: InputMaybe<Scalars['Int']['input']>;
  examGroupId?: InputMaybe<Scalars['Int']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** ISO-8601 */
  lastMenstruation?: InputMaybe<Scalars['Date']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['Int']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type ReportInventoryByDoctor = EntityInterface & {
  __typename?: 'ReportInventoryByDoctor';
  doctor?: Maybe<Doctor>;
  id?: Maybe<Scalars['Object']['output']>;
  inventory?: Maybe<Inventory>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type Response = {
  __typename?: 'Response';
  message?: Maybe<Scalars['String']['output']>;
  result: Scalars['Boolean']['output'];
};

export enum ResultDeliveryMethod {
  Biorec = 'BIOREC',
  Email = 'EMAIL',
  Other = 'OTHER',
  Printed = 'PRINTED'
}

export type SendDataInput = {
  emailContent?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  pdfContent?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<Scalars['String']['input']>;
};

export type Settings = EntityInterface & {
  __typename?: 'Settings';
  content?: Maybe<Scalars['Object']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
};

export type SettingsDtoInput = {
  content?: InputMaybe<Scalars['Object']['input']>;
  context?: InputMaybe<Scalars['String']['input']>;
};

export type SettingsPk = {
  __typename?: 'SettingsPK';
  context?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
};

export type SortMetadataInput = {
  field?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type StatsCategory = {
  __typename?: 'StatsCategory';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type StatsPagedList_DoctorExamStats = {
  __typename?: 'StatsPagedList_DoctorExamStats';
  categories?: Maybe<Array<Maybe<StatsCategory>>>;
  items?: Maybe<Array<Maybe<DoctorExamStats>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type StatsPagedList_DoctorReferralStats = {
  __typename?: 'StatsPagedList_DoctorReferralStats';
  categories?: Maybe<Array<Maybe<StatsCategory>>>;
  items?: Maybe<Array<Maybe<DoctorReferralStats>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Tag = {
  __typename?: 'Tag';
  color?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Object']['output']>;
  model: TagModel;
  name: Scalars['String']['output'];
  /** ISO-8601 */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type TagDtoInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<TagModel>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum TagModel {
  Doctor = 'DOCTOR',
  ExamGroup = 'EXAM_GROUP',
  ExamTemplate = 'EXAM_TEMPLATE'
}

export type UserDashboard = {
  __typename?: 'UserDashboard';
  examsPerDay?: Maybe<Scalars['String']['output']>;
  monthlyExams?: Maybe<Scalars['Int']['output']>;
  monthlyInvoices?: Maybe<Scalars['Int']['output']>;
  monthlyPatients?: Maybe<Scalars['Int']['output']>;
  monthlyReceipts?: Maybe<Scalars['Int']['output']>;
  totalDoctors?: Maybe<Scalars['Int']['output']>;
  totalExams?: Maybe<Scalars['Int']['output']>;
  totalPatients?: Maybe<Scalars['Int']['output']>;
};

export type UserDetails = {
  __typename?: 'UserDetails';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};
