import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	inject,
	HostListener,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';

@Component({
	template: '',
})
export abstract class AbstractForm<T> implements OnInit, OnDestroy {

	private _model: T;

	protected readonly closeOnEnter: boolean = true;

	@Input()
	set model(model: T) {
		this._model = model;
		this.origModel = JSON.parse(JSON.stringify(model));
	}
	get model() {
		return this._model;
	}

	protected origModel: T;

	protected confirmation: Observable<boolean>;
	protected subscriptions = new Subscription();

	@Output()
	cancel = new EventEmitter();
	@Output()
	close = new EventEmitter();
	@Output()
	reset = new EventEmitter();
	@Output()
	save = new EventEmitter();

	@ViewChild(NgForm, { static: false })
	protected ngForm: NgForm;

	protected mSvc = inject(MessageService);
	protected cSvc = inject(ConfirmationService);

	protected abstract submit(): Observable<T>;

	get isFormPristine(): boolean {
		return this.ngForm?.form.touched && this.ngForm?.form.pristine;
	}

	onSubmit(close = false) {
		const valid = this.formValid();
		if (valid instanceof Promise) {
			valid.then(result => result && this._onSubmit(close))
		} else {
			this._onSubmit(close);
		}
	}

	private _onSubmit(close: boolean) {
		this.submit().subscribe((result) => {
			this.onAfterSubmit(result);
			if (close) {
				this.close.emit(null);
			}
		});
	}

	onDeactivate() {
		return this.confirmation;
	}

	onReset() {
		this.subscriptions.add(
			this.confirmation.subscribe((result) => {
				if (result) {
					this.ngForm.form.markAsPristine();
					this.model = JSON.parse(JSON.stringify(this.origModel));
					this.ngForm?.reset(this.model);
					this.reset.emit(this.model);
				}
			})
		);
	}

	onCancel(): void {
		this.subscriptions.add(
			this.confirmation.subscribe((result) => {
				result ? this.cancel.emit(null) : null;
			})
		);
	}

	ngOnInit(): void {
		this.confirmation = new Observable<boolean>((subscriber) => {
			if (this.ngForm?.dirty) {
				this.cSvc.confirm({
					key: 'global',
					header: $localize`Are you sure?`,
					message: $localize`Your changes will be lost!`,
					accept: () => subscriber.next(true),
				});
				return;
			}
			subscriber.next(true);
		});
	}

	protected formValid(): boolean | Promise<boolean> {
		this.ngForm?.form.markAllAsTouched();
		if (this.ngForm.invalid) {
			this.mSvc.add({
				summary: $localize`Error`,
				detail: $localize`The form contains invalid data!`,
				severity: 'error',
			});
			return false;
		}
		return true;
	}

	protected onAfterSubmit(result: T) {
		if (result) {
			this.ngForm.form.markAsPristine();
			this.save.emit(result);
			this.mSvc.add({
				summary: $localize`Success`,
				detail: $localize`Data has been saved successfully!`,
				severity: 'success',
			});
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	@HostListener('window:keydown.enter', ['$event'])
	onEnterKey() {
		if (this.formValid()) {
			this.onSubmit(this.closeOnEnter);
		}
	}
}
