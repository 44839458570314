import { Input, Component, OnInit, forwardRef, Optional } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ImageDto } from '../../../domain';
import { ImagesBrokerService } from '../images-broker.service';
import { ImagesServiceGql } from '../images.service.gql';
import { NgStyle } from '@angular/common';

@Component({
	standalone: true,
	selector: 'br-image',
	templateUrl: 'image.component.html',
	styleUrls: ['image.component.scss'],
	host: {
		'[class.empty]': '!image',
		'[class.p-inputwrapper-filled]': '!!image',
		class: 'flex flex-column',
		'[attr.mode]': 'mode',
	},
	imports: [NgStyle, FormsModule],
	providers: [
		ImagesServiceGql,
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ImageComponent),
			multi: true,
		},
	],
})
export class ImageComponent implements OnInit {
	// ---
	// not used here,but we need them to avoid errors
	@Input()
	public mode: string;
	@Input()
	public defaultGroup: number;
	@Input()
	public subType: string;
	// ---

	@Input()
	public style: { [key: string]: string };
	@Input()
	public imgStyle: { [key: string]: string };
	@Input()
	public width: number;
	@Input()
	public height: number;

	@Input()
	public size: 'full' | 'thumbnail' = 'full';

	protected image: ImageDto;
	protected readonly emptySource: string =
		'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAADUlEQVQI12NgYGBgAAAABQABXvMqOgAAAABJRU5ErkJggg==';

	private model: any;
	private originalModel: any;
	private changeFn: any;
	private touchedFn: any;

	public constructor(
		private svc: ImagesServiceGql,
		private confirmSvc: ConfirmationService,
		@Optional() private brokerSvc: ImagesBrokerService = null
	) {
	}

	public ngOnInit() {
		if (!this.imgStyle) {
			this.imgStyle = {};
		}
		if (!this.style) {
			this.style = {
				'max-width': '100%',
				'max-height': '100%',
			};
		}
		if (
			!this.imgStyle.width &&
			!this.imgStyle['max-width'] &&
			(this.style.width || this.style['max-width'])
		) {
			this.imgStyle['max-width'] =
				this.style.width || this.style['max-width'];
		}
		if (
			!this.imgStyle.height &&
			!this.imgStyle['max-height'] &&
			(this.style.height || this.style['max-height'])
		) {
			this.imgStyle['max-height'] =
				this.style.height || this.style['max-height'];
		}
		this.loadContent();
	}

	private loadContent() {
		if (this.model) {
			this.svc
				.get(this.model.id || this.model)
				.subscribe((img) => {
					this.image = img
				});
		} else {
			this.image = null;
		}
	}

	public writeValue(value: any) {
		this.model = value;
		// keep the initial id of image
		this.originalModel = this.model;
		this.loadContent();
	}

	public registerOnChange(fn: any) {
		this.changeFn = fn;
	}

	public registerOnTouched(fn: any) {
		this.touchedFn = fn;
	}

	private notifyForChange() {
		this.changeFn(this.model);
		this.touchedFn();
	}

	private get isNativeImage(): boolean {
		return this.brokerSvc.isNativeImage(this.subType);
	}

	protected confirmDelete(event: MouseEvent) {
		this.confirmSvc.confirm({
			key: 'global',
			message: $localize`Are you sure you want to delete?`,
			acceptLabel: $localize`Yes`,
			accept: () => {

				// if native and having new (unsaved) content and a previous
				// (non-deleted) image, revert to previous image and
				// do not revert if new image is not uploaded on previous (non-deleted)
				if (this.isNativeImage && this.model && this.image?.id && this.model?.name) {
					this.model = { id: this.image.id };
					this.loadContent();
				} else {
					this.image = null;
					this.model = null;
				}
				this.notifyForChange();
			},
		});
	}

	protected showImagesDialog(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		if (this.mode == 'edit' && this.brokerSvc) {
			this.brokerSvc.emitActivate({
				subType: this.subType.toUpperCase(),
				defaultGroup: this.defaultGroup,
				model: this.isNativeImage ? null : this.model,
			});

			let sub = this.brokerSvc.onDeactivate.subscribe((payload) => {
				if (payload) {
					if (this.isNativeImage) {
						this.model = {
							id: this.originalModel || null,
							...payload,
						};
						// keep id for revert to original image, but only if it has not been deleted
						this.image = { id: this.image?.id || null, ...payload };
					} else {
						this.model = payload.id;
						if (payload[this.size]) {
							this.image = payload;
						} else {
							this.loadContent();
						}
					}
					this.notifyForChange();
				} else {
					// use originalModel if cancel from selector component
					// should always revert to original image (even if another
					// one has been previously selected)
				}
				sub.unsubscribe();
			});
		}
	}
}
